import { useContext, useState, useEffect } from "react";

import { DateTime } from "luxon";

import { Box, Typography, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  ElementsData,
  TypesData,
  MaterialsData,
  CurrentElements,
  CurPreset,
  UserTypes,
  SofaOption,
} from "./DataTypes";
import { client } from "../RpcClient";
import MaterialChooser from "./MaterialChooser";
import ElementChooser from "./ElementChooser";
import Price from "./Price";
import TextOptionsChooser from "../TextOptionsChooser";
import { Trans } from "../Translations";
import { vB } from "../Style";
import { config } from "../Config";
import Banner from "../Banner";

function changeColorTo(newColorId: number, newColorExternalId: string) {
  (window as any).reeWasChangeColorAll = true;
  client.request("SofaChangeColorAll", {
    color_id: newColorId,
    color_external_id: newColorExternalId,
  });
}

export default function Sidebar(props: {
  setLoading: (isLoading: boolean) => void;
  materialsData: MaterialsData | undefined;
  curMaterial: number;
  setCurMaterial: (id: number) => void;
  curColors: { [key: number]: number };
  setCurColors: (newValue: { [key: number]: number }) => void;
  typesData: TypesData | undefined;
  curType: number;
  curPreset: CurPreset | undefined;
  curElements: CurrentElements;
  elementsData: ElementsData | undefined;
  userTypes: UserTypes;
  setUserTypes: (newUserTypes: UserTypes) => void;
  curOptions: Array<{ groupId: number; optionId: number }>;
  setCurOptions: (
    newOptions: Array<{ groupId: number; optionId: number }>
  ) => void;
}) {
  const { _, dynTr } = useContext(Trans);
  const largerThen750 = useMediaQuery("(min-width:750px)");
  const curTypeObj = props.typesData?.data.find(
    (it) => it.id === props.curType
  );
  const curColor = props.materialsData?.data
    ?.find((it) => it.id === props.curMaterial)
    ?.colors.find((it) => it.id === props.curColors[props.curMaterial]);
  const sidebarCss = (realSidebar: boolean) => {
    return {
      pointerEvents: "initial",
      position: "absolute",
      overflowY: "auto",
      "@media (orientation: landscape)": {
        right: 0,
        width: {
          xs: "250px",
          lg: "350px",
          xl: "500px",
        },
        marginBottom: {
          xs: "50px",
          xl: "95px",
        },
        bottom: 0,
        [vB.xs]: {
          top: realSidebar ? 0 : "40px",
        },
        [vB.lg]: {
          top: realSidebar ? 0 : largerThen750 ? "160px" : "40px",
        },
      },
      "@media (orientation: portrait)": {
        right: 0,
        left: 0,
        bottom: 0,
        height: realSidebar ? "30%" : "calc(30% + 50px)",
        marginBottom: realSidebar ? "50px" : 0,
      },
    };
  };
  const isVbLg = useMediaQuery(vB.lg);
  const logoIsShown = !isVbLg || !largerThen750;
  const isLandscape = useMediaQuery("(orientation: landscape)");
  if (curTypeObj === undefined) {
    return (
      <Box className="ree-ug-sidebar" sx={sidebarCss(false)}>
        {logoIsShown && (
          <Box component="a" href="/" onDragStart={(e) => e.preventDefault()}>
            <Box
              component="img"
              onDragStart={(e) => e.preventDefault()}
              sx={{
                position: "absolute",
                height: "20px",
                right: "10px",
                top: "10px",
              }}
              src="imgs/barquitecto_logo.webp"
            />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {isLandscape && (
            <Box
              component="img"
              onDragStart={(e) => e.preventDefault()}
              src="imgs/up-arrow.webp"
              sx={{
                width: { xs: "60px", lg: "120px" },
              }}
            />
          )}
          <Typography
            sx={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: { xs: "1em", lg: "1.5em" },
              fontWeight: 300,
              textAlign: "center",
              margin: "30px",
              color: "#000",
            }}
          >
            {_("Choose a sofa type from the toolbar at the top of this page.")}
          </Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        className="ree-ug-sidebar"
        sx={{
          overflow: "hidden",
          ...sidebarCss(true),
        }}
      >
        {logoIsShown && (
          <Box component="a" href="/" onDragStart={(e) => e.preventDefault()}>
            <Box
              component="img"
              onDragStart={(e) => e.preventDefault()}
              sx={{
                margin: "15px",
                marginBottom: 0,
                float: "right",
                height: "20px",
              }}
              src="imgs/barquitecto_logo.webp"
            />
          </Box>
        )}
        <Box sx={{ marginLeft: "25px", marginRight: "25px" }}>
          {(() => {
            const curPreset = props.curPreset;
            if (curPreset !== undefined) {
              if (curPreset.isUserPreset) {
                const curPresetObj = props.userTypes[
                  props.curType
                ]?.presets.find((it) => it.id === curPreset.id);
                if (curPresetObj) {
                  return (
                    <TextField
                      label={_("My sofa's name")}
                      variant="outlined"
                      value={curPresetObj.name}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const newName = event.target.value;
                        const newUserTypes = { ...props.userTypes };
                        for (const preset of newUserTypes[props.curType]
                          .presets) {
                          if (preset.id === curPreset.id) {
                            preset.name = newName;
                            break;
                          }
                        }
                        props.setUserTypes(newUserTypes);
                      }}
                      sx={{
                        marginTop: "15px",
                        color: "#000",
                        "& .MuiInputLabel-root": {
                          fontFamily: '"Montserrat", sans-serif',
                        },
                        "& .MuiOutlinedInput-input": {
                          fontFamily: '"Montserrat", sans-serif',
                          fontSize: { xs: "0.9em", lg: "1.5em" },
                          fontWeight: 600,
                          textAlign: "center",
                          height: { xs: "10px", lg: "20px" },
                        },
                      }}
                    />
                  );
                }
              } else {
                const curPresetObj = curTypeObj?.presets.find(
                  (it) => it.id === curPreset.id
                );
                if (curPresetObj) {
                  return (
                    <Typography
                      sx={{
                        fontFamily: '"Montserrat", sans-serif',
                        fontSize: { xs: "1em", lg: "2.5em" },
                        fontWeight: 600,
                        marginBottom: "0.3em",
                        color: "#000",
                        marginTop: "15px",
                      }}
                    >
                      {dynTr(curPresetObj.translations, (it) => it.name)}
                    </Typography>
                  );
                }
              }
            }
            return false;
          })()}
          <ElementChooser
            curType={curTypeObj}
            curColor={curColor}
            elementsData={props.elementsData}
            curOptions={props.curOptions}
          />
          <MaterialChooser
            materialsData={props.materialsData}
            curMaterial={props.curMaterial}
            setCurMaterial={props.setCurMaterial}
            curColors={props.curColors}
            setCurColors={props.setCurColors}
            changeColorTo={changeColorTo}
            isPopup={false}
            curElements={props.curElements}
            curType={props.curType}
          />
        </Box>
        {curTypeObj.options.map((it) => {
          const optionsGroup = it.sofa_options_groups_id;
          const curSelectedId = props.curOptions.find(
            (it) => it.groupId === optionsGroup.id
          )?.optionId;
          if (curSelectedId === undefined) {
            console.error();
            return <Box />;
          }
          const curSelected = optionsGroup.options.find(
            (it) => it.id === curSelectedId
          );
          if (curSelected === undefined) {
            console.error();
            return <Box />;
          }
          return (
            <Box
              key={optionsGroup.id}
              sx={{ marginLeft: "25px", marginRight: "25px" }}
            >
              <TextOptionsChooser<SofaOption>
                mainTitle={dynTr(optionsGroup.translations, (it) => it.name)}
                options={optionsGroup.options}
                getTitle={(option) =>
                  dynTr(option.translations, (it) => it.name)
                }
                getId={(option) => option.id}
                selectedOption={curSelected}
                setSelectedOption={async (option) => {
                  const newOptions = [...props.curOptions];
                  for (let i = 0; i < newOptions.length; ++i) {
                    if (newOptions[i].groupId === optionsGroup.id) {
                      newOptions[i].optionId = option.id;
                      props.setCurOptions(newOptions);
                      break;
                    }
                  }
                  await client.request("SofaSwitchOption", {
                    group_external_id: optionsGroup.external_id,
                    external_id: option.external_id,
                    is_instant: false,
                  });
                }}
                isPopup={false}
                columns={2}
              />
            </Box>
          );
        })}
        <Banner />
        <Box sx={{ height: "20px" }} />
        <Price
          setLoading={props.setLoading}
          curElements={props.curElements}
          elementsData={props.elementsData}
          materialsData={props.materialsData}
          curOptions={props.curOptions}
          curTypeObj={curTypeObj}
        />
      </Box>
    );
  }
}
