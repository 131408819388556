import { useContext, useState } from "react";

import { useSearchParams } from "react-router-dom";
import { Box, IconButton, Button, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import {
  UserPreset,
  CurPreset,
  UserTypes,
  TypesData,
  SofaPreset,
  SofaType,
} from "./DataTypes";
import { Trans } from "../Translations";
import { client } from "../RpcClient";
import { config } from "../Config";
import { vB } from "../Style";
import AdminRender from "../AdminRender";
import AdminPricesSync from "./AdminPricesSync";

function iconCss(largerThen750: boolean) {
  return {
    pointerEvents: "none",
    [vB.xs]: {
      height: "60px",
    },
    [vB.lg]: {
      height: largerThen750 ? "80px" : "60px",
    },
  };
}

function PresetChoice(props: {
  className: string;
  selected: boolean;
  preset: SofaPreset;
  onClick: () => void;
}) {
  const { dynTr } = useContext(Trans);
  const verticalLg = useMediaQuery(vB.lg);
  const largerThen750 = useMediaQuery("(min-width:750px)");
  return (
    <Button
      className={props.className}
      sx={{
        marginRight: "10px",
        minWidth: 0,
        padding: 0,
        lineHeight: "1.1em",
        opacity: props.selected ? 1.0 : 0.7,
        ":hover": {
          opacity: 1.0,
          backgroundColor: "#fff",
        },
      }}
      disabled={props.selected}
      onClick={props.onClick}
    >
      {props.preset.icon && (
        <Box
          component="img"
          onDragStart={(e) => e.preventDefault()}
          src={`${config.apiUrl}/assets/${props.preset.icon}`}
          sx={iconCss(largerThen750)}
        />
      )}
      {verticalLg && largerThen750 && (
        <Box
          sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}
        >
          <Typography
            component="span"
            sx={{
              fontFamily: '"Montserrat", sans-serif',
              textTransform: "none",
              // font: 'normal 100% "Open Sans", sans-serif',
              fontWeight: "bold",
              fontSize: "0.9em",
              color: "#000",
              marginBottom: "0.3em",
            }}
          >
            {dynTr(props.preset.translations, (it) => it.name)}
          </Typography>
          {props.preset.width !== null && props.preset.height !== null && (
            <Typography
              component="span"
              sx={{
                fontFamily: '"Montserrat", sans-serif',
                textTransform: "none",
                // font: 'normal 100% "Open Sans", sans-serif',
                fontWeight: "normal",
                fontSize: "0.85em",
                color: "#333333",
              }}
            >
              {props.preset.width} x {props.preset.height}
            </Typography>
          )}
        </Box>
      )}
    </Button>
  );
}

function TypeChoice(props: {
  isFirst: boolean;
  type: SofaType;
  onClick: () => void;
}) {
  const { dynTr } = useContext(Trans);
  const largerThen750 = useMediaQuery("(min-width:750px)");
  return (
    <Button
      className={props.isFirst ? "ree-ug-first-type-button" : undefined}
      sx={{
        /*
         [vB.xs]: {
minWidth: "100px",
},
[vB.lg]: {
minWidth: "150px",
},
*/
        marginRight: "10px",
        minWidth: 0,
        padding: 0,
        lineHeight: "1.1em",
        opacity: 0.7,
        ":hover": {
          opacity: 1.0,
          backgroundColor: "#fff",
        },
      }}
      onClick={props.onClick}
    >
      {props.type.icon && (
        <Box
          component="img"
          onDragStart={(e) => e.preventDefault()}
          src={`${config.apiUrl}/assets/${props.type.icon}`}
          sx={iconCss(largerThen750)}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: props.type.icon == null ? "0" : "5px",
        }}
      >
        <Typography
          component="span"
          sx={{
            fontFamily: '"Montserrat", sans-serif',
            textTransform: "none",
            // font: 'normal 100% "Open Sans", sans-serif',
            fontWeight: "bold",
            fontSize: "0.9em",
            color: "#000",
            marginBottom: "0.3em",
          }}
        >
          {dynTr(props.type.translations, (it) => it.name)}
        </Typography>
      </Box>
    </Button>
  );
}

function UserPresetChoice(props: {
  selected: boolean;
  name: string;
  onClick: () => void;
  onDelete: () => void;
}) {
  const largerThen750 = useMediaQuery("(min-width:750px)");
  return (
    <Box
      sx={{
        display: "inline-block",
        position: "relative",
        marginRight: "15px",
      }}
    >
      <Button
        sx={{
          [vB.xs]: {
            height: "60px",
          },
          [vB.lg]: {
            height: largerThen750 ? "80px" : "60px",
          },
          minWidth: 0,
          padding: 0,
          paddingRight: props.selected ? "15px" : 0,
          lineHeight: "1.1em",
          opacity: props.selected ? 1.0 : 0.7,
          ":hover": {
            opacity: 1.0,
            backgroundColor: "#fff",
          },
        }}
        disabled={props.selected}
        onClick={props.onClick}
      >
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
          <Typography
            component="span"
            sx={{
              fontFamily: '"Montserrat", sans-serif',
              textTransform: "none",
              // font: 'normal 100% "Open Sans", sans-serif',
              fontWeight: "bold",
              fontSize: "0.9em",
              color: "#000",
              marginBottom: "0.3em",
            }}
          >
            {props.name}
          </Typography>
        </Box>
      </Button>
      {props.selected && (
        <IconButton
          sx={{
            position: "absolute",
            top: "0",
            right: "-15px",
            border: "solid 1px",
            padding: "3px",
          }}
          onClick={props.onDelete}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default function PresetChooser(props: {
  typesData: TypesData | undefined;
  curType: number;
  curPreset: CurPreset | undefined;
  userTypes: UserTypes;
  onTypeClick: (type: SofaType) => void;
  onPresetClick: (preset: SofaPreset) => void;
  onUserPresetClick: (preset: UserPreset) => void;
  onUserPresetDelete: (preset: UserPreset) => void;
  onTypesButtonClick: () => void;
  showCurrentElements: () => void;
}) {
  const { _ } = useContext(Trans);
  const verticalLg = useMediaQuery(vB.lg);
  const largerThen750 = useMediaQuery("(min-width:750px)");
  const [params] = useSearchParams();
  const curPreset = props.curPreset;
  const [isRedCheckOn, setIsRedCheckOn] = useState<boolean>(true);
  const children = [];
  if (props.curType === -1) {
    children.push(
      ...(props.typesData?.data ?? []).map((it, index) => {
        return (
          <TypeChoice
            isFirst={index === 0}
            key={it.id}
            type={it}
            onClick={() => props.onTypeClick(it)}
          />
        );
      })
    );
  } else {
    children.push(
      ...(
        props.typesData?.data.find((it) => it.id === props.curType)?.presets ??
        []
      ).map((it, index) => {
        return (
          <PresetChoice
            className={`ree-ug-preset-button-${index}`}
            key={it.id}
            selected={
              curPreset !== undefined &&
              !curPreset.isUserPreset &&
              it.id === curPreset.id
            }
            preset={it}
            onClick={() => props.onPresetClick(it)}
          />
        );
      })
    );
    children.push(
      ...(props.userTypes[props.curType]?.presets ?? []).map((it) => {
        return (
          <UserPresetChoice
            key={it.id}
            selected={
              curPreset !== undefined &&
              curPreset.isUserPreset &&
              it.id === curPreset.id
            }
            name={it.name}
            onClick={() => props.onUserPresetClick(it)}
            onDelete={() => props.onUserPresetDelete(it)}
          />
        );
      })
    );
  }
  return (
    <Box
      sx={{
        className: "ree-ug-types",
        pointerEvents: "initial",
        position: "absolute",
        left: 0,
        top: 0,
        "@media (orientation: portrait)": {
          right: 0,
        },
        "@media (orientation: landscape)": {
          right:
            props.curType === -1
              ? "0"
              : { xs: "250px", lg: "350px", xl: "500px" },
        },
        [vB.xs]: {
          height: "60px",
        },
        [vB.lg]: {
          height: largerThen750 ? "160px" : "60px",
        },
      }}
    >
      <Box
        sx={{
          [vB.xs]: {
            display: params.get("adminKey") !== null ? "block" : "none",
          },
          [vB.lg]: {
            marginTop: "15px",
            display: largerThen750 ? "block" : "none",
            height: "60px",
          },
          display: "flex",
          alignItems: "center",
        }}
      >
        {largerThen750 && (
          <Box component="a" href="/" onDragStart={(e) => e.preventDefault()}>
            <Box
              component="img"
              onDragStart={(e) => e.preventDefault()}
              sx={{ height: "40px", marginLeft: "25px" }}
              src="imgs/barquitecto_logo.webp"
            />
          </Box>
        )}
        {params.get("adminKey") !== null && (
          <Button
            sx={{ position: "absolute", right: "210px" }}
            onClick={props.showCurrentElements}
            variant="outlined"
          >
            Elements
          </Button>
        )}
        {params.get("adminKey") !== null && (
          <Button
            sx={{ position: "absolute", right: "330px" }}
            onClick={async () => {
              const res = await client.request("SofaAdminSwitchRed", {});
              setIsRedCheckOn(res.is_red_on);
            }}
            variant="outlined"
          >
            {isRedCheckOn ? "Red check on" : "No red for me"}
          </Button>
        )}
        {params.get("adminKey") !== null && (
          <Box
            sx={{
              display: "inline-flex",
              position: "absolute",
              right: "485px",
            }}
          >
            <AdminRender />
          </Box>
        )}
        {params.get("adminKey") !== null && (
          <Box
            sx={{
              display: "inline-flex",
              position: "absolute",
              right: "620px",
            }}
          >
            <AdminPricesSync />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {props.curType === -1 ? null : verticalLg && largerThen750 ? (
          <Button
            className="ree-ug-back-to-types-button"
            sx={{
              // position: "absolute",
              marginLeft: "20px",
              // bottom: "27px",
              flex: "none",
            }}
            key={-1}
            variant="outlined"
            startIcon={<ArrowBackIosNewIcon />}
            onClick={props.onTypesButtonClick}
          >
            {_("Back")}
          </Button>
        ) : (
          <IconButton
            className="ree-ug-back-to-types-button"
            key={-1}
            onClick={props.onTypesButtonClick}
            color="primary"
            sx={{
              marginLeft: "var(--sal)",
              zIndex: 100,
              backgroundColor: "white",
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        )}
        <Box
          className={props.curType === -1 ? "ree-ug-types" : "ree-ug-presets"}
          sx={{
            marginLeft:
              props.curType === -1
                ? verticalLg && largerThen750
                  ? "5px"
                  : "calc(var(--sal) + 5px)"
                : verticalLg && largerThen750
                ? "20px"
                : "5px",
            // display: "flex",
            [vB.xs]: {
              // alignItems: "center",
              // gap: "10px",
              height: "60px",
            },
            [vB.lg]: {
              // alignItems: "center",
              // gap: "25px",
              height: largerThen750 ? "80px" : "60px",
            },
            overflow: "auto",
            whiteSpace: "nowrap",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
