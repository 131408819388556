import { ReactNode, useEffect, useState, useContext } from "react";

import { Typography, Box } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  useTheme,
  Theme,
} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { apps } from "./Apps";
import SofaIndex from "./Sofas/SofaIndex";
import PergIndex from "./Perg/PergIndex";
import { Space } from "./DataTypes";
import { PixelStreamingWrapper } from "./PixelStreamingWrapper";
import { vB } from "./Style";
import { config } from "./Config";
import CircularStatic from "./CircularStatic";
import { Trans } from "./Translations";
import { client } from "./RpcClient";
import { logEvent } from "./Metrics";
import WindowIndex from "./Windows/WindowIndex";

function renderImage(imgSrc: null | string) {
  return imgSrc === null ? (
    <Box
      sx={{
        width: "0",
        height: "0",
      }}
    ></Box>
  ) : (
    <img
      alt="Loading logo"
      style={{ maxHeight: "40vh", maxWidth: "40vw", marginBottom: "50px" }}
      src={imgSrc}
    />
  );
}

function renderBox(children: ReactNode) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#fff",
      }}
    >
      {children}
    </Box>
  );
}

window.addEventListener(
  "touchmove",
  (event: TouchEvent) => {
    // Leave this be as it fixes a safari bug.
    // Before safari would scroll when dragging an element, now it doesn't.
  },
  { passive: false, capture: false }
);

setInterval(() => {
  const cb = (window as any).reeLogTime;
  if (cb !== undefined) {
    cb();
  }
}, 10 * 1000);

setInterval(() => {
  const cb = (window as any).reeSofaSaveCameraPosition;
  if (cb !== undefined) {
    cb();
  }
}, 8 * 1000);

export default function App() {
  const { _ } = useContext(Trans);
  const [theme, setTheme] = useState<Theme>(useTheme());
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [signalingServer, setSignalingServer] = useState<string>();
  const [wasInactive, setWasInactive] = useState(false);
  const [space, setSpace] = useState<Space>();
  const [loadingLevel, setLoadingLevel] = useState(true);

  const app = apps.find((it) => config.spaceId === it.link);
  if (app === undefined) {
    throw Error();
  }

  useEffect(() => {
    if (app !== undefined) {
      if (app.spaceId !== undefined) {
        fetch(`${config.apiUrl}/items/spaces/${app.spaceId}`)
          .then((res) => res.json())
          .then((res) => {
            setSpace(res.data);
            const primaryColor = res.data.primary_color;
            if (typeof primaryColor === "string" && primaryColor.length > 0) {
              setTheme(
                createTheme({
                  typography: {
                    allVariants: {
                      fontFamily: "Montserrat",
                    },
                  },
                  palette: {
                    primary: {
                      main: primaryColor,
                    },
                  },
                })
              );
            }
            const image = res.data.loading_image;
            if (typeof image === "string" && image.length > 0) {
              setImgSrc(`${config.apiUrl}/assets/${image}`);
            } else {
              setImgSrc("/logo.svg");
            }
          });
      } else {
        setImgSrc("/logo.svg");
      }
    }
  }, [app]);

  useEffect(() => {
    let hasSignalingServer = false;
    let lastInteraction = Date.now();
    let getSignalingServerLock = false;
    async function getSignalingServer() {
      if (getSignalingServerLock) {
        console.log("Already getting signaling server not continuing...");
        return;
      }
      console.log("Started signaling server get");
      function mySleep(delay: number) {
        return new Promise(function (resolve) {
          setTimeout(resolve, delay);
        });
      }
      getSignalingServerLock = true;
      try {
        if (config.signalsOverrideLocal !== undefined) {
          setSignalingServer(config.signalsOverrideLocal);
          hasSignalingServer = true;
        } else {
          let signals;
          // "only" wait for 10 minutes
          for (let i = 0; i < 600; ++i) {
            try {
              const response = await fetch(
                `${config.matchmakerURL}/signallingserver`
              );
              const responseData = await response.json();
              signals = responseData.signallingServer;
              if (typeof signals !== "string" || signals.length === 0) {
                console.log("No empty signalling servers found, retrying");
              } else {
                break;
              }
            } catch (err) {
              console.error("Failed getting signalling server from matchmaker");
              console.error(err);
            }
            await mySleep(1000);
          }
          if (typeof signals !== "string" || signals.length === 0) {
            throw Error();
          }
          // const domainOnly = signals.substring(0, signals.length - 5);
          // console.log("SETTING signaling server URL");
          // setSignalingServer(`${config.signalsProtocol}${domainOnly}`);
          console.log(
            `SETTING signaling server URL to ${config.signalsProtocol}${signals}`
          );
          setSignalingServer(`${config.signalsProtocol}${signals}`);
          hasSignalingServer = true;
          // (window as any).reeFilesUrl = `https://${domainOnly}/files`;
          (window as any).reeFilesUrl = `https://${signals}/files`;
        }
      } finally {
        getSignalingServerLock = false;
      }
    }
    function resetTimer() {
      lastInteraction = Date.now();
    }
    document.addEventListener("mousedown", resetTimer);
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("touchstart", resetTimer);
    document.addEventListener("scroll", resetTimer);
    document.addEventListener("keydown", resetTimer);
    setInterval(() => {
      const timeoutTimeSeconds = 60;
      const diff = Date.now() - lastInteraction;
      if (diff > timeoutTimeSeconds * 1000) {
        if (hasSignalingServer) {
          console.log("DISCONNECTING BECAUSE OF INACTIVITY");
          logEvent("afk_sleep_start");
          setWasInactive(true);
          setSignalingServer(undefined);
          hasSignalingServer = false;
        }
      } else {
        if (!hasSignalingServer) {
          getSignalingServer();
        }
      }
    }, 1000);
  }, [setSignalingServer, setWasInactive]);

  /*
  (window as any).reeSofaSaveCameraPosition = async function () {
    if (!isLoading && signalingServer !== undefined) {
      (window as any).reeSofaCameraPosition = await client.request(
        "SofaCameraPosition",
        {}
      );
    }
  };
  */

  (window as any).reeLogTime = function () {
    if (!isLoading && signalingServer !== undefined) {
      logEvent("new_10_seconds");
    }
  };

  const largerThen750 = useMediaQuery("(min-width:750px)");
  let child;
  if ((isLoading || signalingServer === undefined) && !wasInactive) {
    child = renderBox([
      renderImage(imgSrc),
      <CircularStatic showPercentsAfter={5 * 1000} time={210 * 1000} />,
    ]);
  } else {
    if (space === undefined) {
      throw Error();
    }
    child = (
      <Box sx={{ position: "fixed", height: "100%", width: "100%" }}>
        <Box
          sx={
            space.space_type == "window"
              ? {
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }
              : {
                  position: "absolute",
                  backgroundColor: "black",
                  "@media (orientation: landscape)": {
                    width: {
                      xs: "calc(100vw - 250px)",
                      lg: "calc(100vw - 350px)",
                      xl: "calc(100vw - 500px)",
                    },
                    [vB.xs]: {
                      marginTop: "60px",
                      height: "calc(100vh - 60px)",
                    },
                    [vB.lg]: {
                      marginTop: largerThen750 ? "160px" : "60px",
                      height: largerThen750
                        ? "calc(100vh - 160px)"
                        : "calc(100vh - 60px)",
                    },
                  },
                  "@media (orientation: portrait)": {
                    width: "100%",
                    [vB.xs]: {
                      marginTop: "60px",
                      height: "calc(70% - 60px - 50px)",
                    },
                    [vB.lg]: {
                      marginTop: largerThen750 ? "160px" : "60px",
                      height: largerThen750
                        ? "calc(70% - 160px - 50px)"
                        : "calc(70% - 60px - 50px)",
                    },
                  },
                }
          }
        >
          {signalingServer === undefined ? (
            <Box
              sx={{
                backgroundColor: "#fff",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  flexGrow: 0,
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: {
                      xs: "20px",
                      lg: "40px",
                    },
                    flexGrow: 1,
                  }}
                >
                  {_("Sleeping")}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    paddingTop: {
                      xs: "15px",
                      lg: "25px",
                    },
                    color: "#808080",
                    fontWeight: 300,
                    fontSize: {
                      xs: "13px",
                      lg: "15px",
                    },
                    flexGrow: 1,
                  }}
                >
                  {_("Click to wake me up.")}
                </Typography>
                <img
                  style={{ marginTop: "30px", display: "block" }}
                  src={
                    config.spaceId === "feydom"
                      ? "imgs/feydom_sleeping.gif"
                      : config.spaceId === "barquitecto"
                      ? "imgs/barquitecto_logo.webp"
                      : "imgs/afu_logo.svg"
                  }
                />
              </Box>
            </Box>
          ) : (
            <PixelStreamingWrapper
              initialSettings={{
                AutoPlayVideo: true,
                AutoConnect: true,
                MaxReconnectAttempts: 365 * 24 * 60 * 60,
                ss: signalingServer,
                StartVideoMuted: true,
                HoveringMouse: true,
                FakeMouseWithTouches: true,
                MatchViewportRes: true,
                WebRTCFPS: 25,
              }}
              loadingLevel={loadingLevel}
              setLoadingLevel={setLoadingLevel}
            />
          )}
        </Box>
        {space.space_type === "pergola" ? (
          <PergIndex
            setLoading={setLoading}
            setLoadingLevel={setLoadingLevel}
          />
        ) : space.space_type == "window" ? (
          <WindowIndex />
        ) : (
          <SofaIndex setLoading={setLoading} />
        )}
      </Box>
    );
  }
  let title = _("Configurator");
  if (space !== undefined) {
    if (space.space_type === "pergola") {
      title = _("Configurator - AF Überdachung");
    } else if (space.space_type === "window") {
      title = _("Configurator");
    } else {
      title = _("Build the sofa of your dreams - FEYDOM");
    }
  }
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={_(
            "Mix and match the colours and elements, the possibilities are endless!"
          )}
        />
      </Helmet>
      <ThemeProvider theme={theme}>{child}</ThemeProvider>
    </HelmetProvider>
  );
}
